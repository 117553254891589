import React from 'react'
import { Spacer } from '@cleartrip/ct-design-components'
import { isEmpty } from '@/utils/general/browserHelper'

// Define types for the placeholder items
interface PlaceHolderItem {
    type: 'link' | 'bold'
    placeHolder: string
    replaceText: string
    url?: string
}

// Define types for the ParagraphText data prop
interface ParagraphTextData {
    value?: string
    placeHoldersList?: PlaceHolderItem[]
}

// Define props for ParagraphText component
interface ParagraphTextProps {
    data?: ParagraphTextData
}

// Define types for list item
interface ListItem {
    type: 'text' | 'list' | 'space'
    values?: ListItem[]
    placeHoldersList?: PlaceHolderItem[]
    value?: string
}

// Define props for ListCreator component
interface ListCreatorProps {
    data: { values?: ListItem[] }
}

// Define types for FAQ item
interface FaqItem {
    heading: string
    values: ListItem[]
}

// Define props for HomePageFaq component
interface HomePageFaqProps {
    data: FaqItem[]
    showFlightsFaq: boolean
}

const cleartripHomeFaq: FaqItem[] = [
  {
    heading: 'Booking flights online on Cleartrip?',
    values: [
      {
        type: 'text',
        value: 'Forget about any hassle or extra effort, as on Cleartrip, everything is taken care of. Our round-trip and multi-city choices make booking flight tickets and organising your journey easier. Making a reservation for an airline ticket online has always been challenging.'
      },
      {
        type: 'list',
        values: [
          {
            type: 'text',
            value: 'Open an account with us and start your path to comfortable, worry-free travel.'
          },
          {
            type: 'text',
            value: "Once you've input your origin and destination cities, click enter as the first step to buy your tickets."
          },
          {
            type: 'text',
            value: 'The search list page shows the results for both the forward and return in a split-screen style, allowing you to choose flights for a round-trip in one go.'
          },
          {
            type: 'text',
            value: 'The multi-city search page displays a selection of complete itineraries, saving you the trouble of calculating time, transfers, and layovers and allowing you to make your online flight ticket.'
          }
        ]
      },
      {
        type: 'space'
      },
      {
        type: 'text',
        value: ''
      }
    ]
  },
  {
    heading: 'International Flights on Cleartrip',
    values: [
      {
        type: 'text',
        value: "Book international flights on Cleartrip as it offers discounts of up to 12% on flights to London! To take advantage of this offer, simply enter the promo code 'CTDOTW' during the booking process. Whether you're travelling for business or pleasure, take advantage of this incredible offer and book your flight with Cleartrip today!"
      }
    ]
  },
  {
    heading: 'Domestic Flights on Cleartrip',
    values: [
      {
        type: 'text',
        value: 'Book domestic flights on Cleartrip to ensure you receive the most excellent deal. We highlight special deals, specials, and other promotions on the checkout page. Our platform enables easy cancellations or adjustments after booking, eliminating the need to call the airlines.'
      },
      {
        type: 'space'
      },
      {
        type: 'text',
        value: 'Cleartrip is giving a fantastic discount on domestic flights. You can save up to INR 5,000 on your flight bookings by using the promo code CTFLY. This offer can help you save money on your next holiday or business trip. Simply enter the promo code at checkout to receive the benefits. Please review the terms and conditions for any particular criteria or limitations.'
      },
    ]
  },
  {
    heading: 'Hotel Reservations on Cleartrip',
    values: [
      {
        type: 'text',
        value: "Be assured you get the best deals when you book the <a href='https://www.cleartrip.com/hotels'  style='text-decoration: none'>Cleartrip hotels</a>. It's always advisable to book hotels along with your flight tickets, as this allows you to avail yourself of the best deals and offers. The full-fledged booking can make the whole trip experience hassle-free."
      },
      {
        type: 'space'
      },
      {
        type: 'text',
        value: "Searching for hotel reservation websites can make the booking process tedious. Cleartrip is your go-to hotel booking app for everything from guest homes to resorts, budget-friendly to luxurious, business or leisure. Our handpicked, verified list of 400000+ hotels in 28000+ cities worldwide ensures you have plenty of options and can quickly finish your online hotel booking. Find a list of hotel chains, including Oyo Rooms, FabHotels, and Treebo hotels. Book hotels in Delhi, Mumbai, Bangalore, Goa, and other locations quickly and easily."
      },
      {
        type: 'space'
      },
      {
        type: 'text',
        value: "We can streamline your hotel room booking search using various filters and sorting options. It displays all the information about your favourite hotel, including a description, highlights, images, amenities, room kinds, and rates, all in one spot. Additional options such as pay-at-hotel, rapid checkout, and free cancellations simplify the hotel booking process."
      }
    ]
  },
  {
    heading: 'Online Bus Booking on Cleartrip',
    values: [
      {
        type: 'text',
        value: "Avail of <a href='https://www.cleartrip.com/bus' style='text-decoration: none'> Cleartrip bus booking </a> services today! We are a customer-focused platform, and we consistently prioritise the needs of our clients. Your bus reservation is entirely transparent, with no hidden charges. That's not all; you may now order bus tickets with no convenience fees. You may direct your issues (if any) to our 24x7 customer care, which connects you to a customer support agent rather than an IVR."
      },
      {
        type: 'space'
      },
      {
        type: 'text',
        value: "By booking your seat exclusively through the Cleartrip app, you can avail of offers and save a lot of money on your bus travel costs. Your hunt for dependable bus travel ends here. With over ten lakh buses, from non-AC sleepers to Volvo buses, Cleartrip makes online bus booking easier and faster."
      }
    ]
  },
  {
    heading: 'Train Reservations on Cleartrip ',
    values: [
      {
        type: 'text',
        value: "Cleartrip train booking is a smooth and easy process. It enables you to plan and book train tickets and search for them. Simply click 'check availability' on the search results page to see a book button. It's fairly straightforward. Enter your origin, destination, travel date, and class, then click 'Search'."
      },
      {
        type: 'space'
      },
      {
        type: 'text',
        value: "We connect in real-time to the Indian Railways reservation system to obtain the most recent availability data. To provide you with a speedier search experience, we cache the trains, routes, and fare data. We ensure we acquire the most recent fares whenever you book a ticket."
      }
    ]
  },
  {
    heading: 'Why Choose Cleartrip?',
    values: [
      {
        type: 'text',
        value: "Scheduling a flight with Cleartrip is relatively simple and quick. You just need to search and book in a few clicks. Cleartrip provides various flight booking options and deals, making planning a trip from your origin to your preferred destination city simple."
      },
      {
        type: 'space'
      },
      {
        type: 'text',
        value: "Cleartrip also offers a variety of options for online hotel bookings. When you choose a hotel or a flight ticket, the best available offers and deals are displayed on the booking page. You can then redeem your offer using any offered offers and coupon codes. Explore all flight and hotel booking discount offers and feel comfortable and relaxed."
      },
      {
        type: 'space'
      },
      {
        type: 'text',
        value: "Cleartrip makes any travel wish possible, from purchasing airline tickets to booking hotels online to reserving rental cars and out-of-town taxis. Indispensable specials and discounted prices, such as the Travel Max Sale, Big Travel Sale, and Cleartrip Tatkal, provide never-before-seen savings, allowing you to book low-cost flights."
      },
      
    ]
  }
];

const flightsFaq: FaqItem[] = [
  {
    heading: 'Offers on Flights with Cleartrip',
    values: [
      {
        type: 'text',
        value: 'Cleartrip collates the best offers to get you the maximum savings when arranging a trip for yourself or the whole family. When you plan to <a href="https://www.cleartrip.com/offers/india/international-flights" style="text-decoration: none">book international flights</a> or are looking to <a href="https://www.cleartrip.com/offers/india/domestic-flights" style="text-decoration: none">book domestic flights </a>, it becomes vital to have the best deals at your disposal. Cleartrip is a platform that provides an exhaustive list of flight and hotel options, offers, and special discounts that will be reflected on the page while you are making your bookings. Check out today! Cleartrip now provides excellent deals on both international and local flights. When purchasing an international flight use the promo code "CTINT" to save up to Rs 10000. You can save up to Rs. 5,000 on domestic flights using the coupon code "CTDOM". Take advantage of these fantastic opportunities to make your trip more inexpensive and fun.'
      },
      {
        type: 'space'
      },
    ]
  },
];

export const ParagraphText: React.FC<ParagraphTextProps> = ({ data }) => {
    const renderText = (): string => {
        let { value = '', placeHoldersList = [] } = data || {}
        if (value && !isEmpty(placeHoldersList)) {
            value = placeHoldersList.reduce((text, item) => {
                const { type, placeHolder, replaceText, url } = item
                if (type === 'link') {
                    return text.replace(
                        placeHolder,
                        `<a href=${url} class="c-secondary-500 td-none hover:td-underline fs-14">${replaceText}</a>`
                    )
                }
                if (type === 'bold') {
                    return text.replace(placeHolder, `<span class="fs-14 fw-600 c-neutral-900">${replaceText}</span>`)
                }
                return text
            }, value)
        }
        return value
    }

    return <p className="c-dark-grey fs-14" dangerouslySetInnerHTML={{ __html: renderText() }}></p>
}

export const ListCreator: React.FC<ListCreatorProps> = ({ data }) => {
  return (
    <ul className="px-6 pt-2" style={{listStyleType : "disc"}}>
      {data.values?.map((listItem, index) => {
        if (listItem.type === 'text') {
          return (
            <li key={index} className="pt-1">
              <ParagraphText data={listItem} />
            </li>
          );
        }
        return null;
      })}
    </ul>
  );
};

const HomePageFaq = ({ showFlightsFaq }:{showFlightsFaq:boolean}) => {
  const data = showFlightsFaq ? flightsFaq : cleartripHomeFaq;
  return (
    <>
      {data.map((faq, index) => (
        <div key={index} className="pt-8">
          <h2 className="pb-4 fw-600 c-neutral-900" style={{ fontSize: '18px' }}>{faq.heading}</h2>
          {faq.values.map((item, subIndex) => {
            if (item.type === 'text') {
              return <ParagraphText key={subIndex} data={item} />;
            }
            if (item.type === 'list') {
              return <ListCreator key={subIndex} data={item} />;
            }
            if (item.type === 'space') {
              return <Spacer key={subIndex} className="pt-4" />;
            }
            return null;
          })}
        </div>
      ))}
    </>
  );
};

export default HomePageFaq

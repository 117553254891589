import { REQUEST_METHODS } from '@/constants/network'
import baseFetch from '@/network/baseFetch'
import { getPartnerIdCfw } from '@/utils/cfw'
import { isEmpty } from '@/utils/general/browserHelper'

export const fetchComponentsController = async (action: any) => {
    try {
        let partnerId = getPartnerIdCfw()
        const partnerIdHeaders = {
            'partner-id': partnerId    
        }
        const { itineraryId, headers = '' } = action
        const URL = `/itin/v7/itinerary/${itineraryId}/fetch/components`
        const response = await baseFetch({
            url: URL,
            method: REQUEST_METHODS.GET,
            headers: {...headers,...partnerIdHeaders}
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.error(e)
        return {}
    }
}
export const fetchUserDataController = async (itineraryId: string) => {
    try {
        const URL = `/itin/v4/itinerary/${itineraryId}/user`

        let headers = { 'grant-token': '' }

        const response = await baseFetch({
            url: URL,
            method: REQUEST_METHODS.GET,
            headers: headers
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.log(e)
        return {}
    }
}
export const fetchAncillariesControllerCFW = async (itineraryId: string) => {
    try {
        let partnerId = getPartnerIdCfw()
        const headers = {
            'partner-id': partnerId    
        }
        const URL = `/itin/v7/${itineraryId}/ancillaries/fetch?includeBaggage=true&includeMeal=true&includeSeat=true`
        const response = await baseFetch({
            url: URL,
            method: REQUEST_METHODS.GET,
            headers
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.log(e)
        return {}
    }
}
export const fetchAncillariesController = async (itineraryId: string) => {
    try {
        const URL = `/itin/v4/${itineraryId}/ancillaries/fetch?includeBaggage=true&includeMeal=true&includeSeat=true`
        const response = await baseFetch({
            url: URL,
            method: REQUEST_METHODS.GET
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.log(e)
        return {}
    }
}
export const fetchAncillariesControllerN = (itineraryId: string, isCFW: boolean) => {
    switch (isCFW) {
        case true:
            return fetchAncillariesControllerCFW(itineraryId)

        case false:
            return fetchAncillariesController(itineraryId)
        default:
            return fetchAncillariesController(itineraryId)
    }
}

export const fetchComponentsControllerN = (action: any) => {
    const { itineraryId, ssr = false, headers = {}, isCFW = action.isCfw } = action
    switch (isCFW as boolean) {
        case true:
            return fetchComponentsControllerCFW({ itineraryId, headers, isSsr: ssr })
        case false:
            return fetchComponentsController(action)
        default:
            return fetchComponentsController(action)
    }
}
export const fetchComponentsControllerCFW = async ({
    itineraryId,
    headers,
    isSsr
}: {
    itineraryId: string
    headers: object
    isSsr: boolean
}) => {
    try {
        let partnerId = getPartnerIdCfw()
        const partnerIdHeaders = {
            'partner-id': partnerId    
        }
        const URL = `/itin/v7/itinerary/${itineraryId}/fetch/components`
        const response = await baseFetch({
            url: URL,
            method: REQUEST_METHODS.GET,
            headers: { ...headers,...partnerIdHeaders }
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        return {}
    }
}
export const fetchInsurancesControllerN = (itineraryId: string, isCFW: boolean) => {
    switch (isCFW) {
        case true:
            return fetchInsurancesControllerCFW(itineraryId)

        case false:
            return fetchInsurancesController(itineraryId)
        default:
            return fetchInsurancesController(itineraryId)
    }
}

export const fetchInsurancesControllerCFW = async (itineraryId: string) => {
    try {
        let partnerId = getPartnerIdCfw()
        const headers = {
            'partner-id': partnerId    
        }
        const URL = `/itin/v7/${itineraryId}/insurances/fetch`
        const response = await baseFetch({
            url: URL,
            method: REQUEST_METHODS.GET,
            headers
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.log(e)
        return {}
    }
}
export const fetchInsurancesController = async (itineraryId: string) => {
    try {
        const URL = `/itin/v4/${itineraryId}/insurances/fetch`
        const response = await baseFetch({
            url: URL,
            method: REQUEST_METHODS.GET
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.log(e)
        return {}
    }
}
export const fetchCouponListControllerN = (itineraryId: string, oldFlow = true, isCFW: boolean) => {
    switch (isCFW) {
        case true:
            return fetchCouponListControllerCFW(itineraryId)

        case false:
            return fetchCouponListController(itineraryId)
        default:
            return fetchCouponListController(itineraryId)
    }
}
export const fetchCouponListControllerCFW = async (itineraryId: string, oldFlow = true) => {
    try {
        let partnerId = getPartnerIdCfw()
        const headers = {
            'partner-id': partnerId    
        }
        const URL = `/itin/v7/itinerary/${itineraryId}/couponList`
        const response = await baseFetch({
            url: URL,
            method: REQUEST_METHODS.GET,
            headers
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.log(e)
        return {}
    }
}
export const fetchCouponListController = async (itineraryId: string) => {
    try {
        const URL = `/itin/v4/itinerary/${itineraryId}/couponList`
        const response = await baseFetch({
            url: URL,
            method: REQUEST_METHODS.GET,
            headers: { OLD_FLOW: 'false' }
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.log(e)
        return {}
    }
}
export const updateItineraryDataN = (payload: object, itineraryId: string, isCFW: boolean) => {
    switch (isCFW) {
        case true:
            return updateItineraryDataCFW(payload, itineraryId)

        case false:
            return updateItineraryData(payload, itineraryId)
        default:
            return updateItineraryData(payload, itineraryId)
    }
}

export const updateItineraryDataCFW = async (payload: object, itineraryId: string) => {
    try {
        let partnerId = getPartnerIdCfw()
        const headers = {
            'partner-id': partnerId    
        }
        const URL = `/itin/v7/itinerary/${itineraryId}/update`
        const response = await baseFetch<ResponseType>({
            url: URL,
            method: REQUEST_METHODS.POST,
            body: JSON.stringify(payload),
            headers
        })
        return response
    } catch (e) {
        console.log(e)
    }
}

export const updateItineraryData = async (payload: object, itineraryId: string) => {
    try {
        const URL = `/itin/v4/itinerary/${itineraryId}/update`
        const response = await baseFetch<ResponseType>({
            url: URL,
            method: REQUEST_METHODS.POST,
            body: JSON.stringify(payload)
        })
        return response
    } catch (e) {
        console.log(e)
    }
}

export const getApplyCouponN = (payload: object, itineraryId: string, isCFW: boolean) => {
    switch (isCFW) {
        case true:
            return getApplyCouponCFW(payload, itineraryId)

        case false:
            return getApplyCoupon(payload, itineraryId)
        default:
            return getApplyCoupon(payload, itineraryId)
    }
}

export const getApplyCouponCFW = async (payload: object, itineraryId: string) => {
    try {
        let partnerId = getPartnerIdCfw()
        const headers = {
            'partner-id': partnerId    
        }
        const URL = `/itin/v7/itinerary/${itineraryId}/applyCoupon`
        const response = await baseFetch<ResponseType>({
            url: URL,
            method: REQUEST_METHODS.POST,
            body: JSON.stringify(payload),
            headers
        })
        return response
    } catch (e) {
        console.log(e)
    }
}
export const getApplyCoupon = async (payload: object, itineraryId: string) => {
    try {
        const URL = `/itin/v4/itinerary/${itineraryId}/applyCoupon`
        const response = await baseFetch<ResponseType>({
            url: URL,
            method: REQUEST_METHODS.POST,
            body: JSON.stringify(payload)
        })
        return response
    } catch (e) {
        console.log(e)
    }
}

export const getFareFamilyInfoN = (itineraryId: string, type: any, isCFW: boolean) => {
    switch (isCFW) {
        case true:
            return getFareFamilyInfoCFW(itineraryId, type)

        case false:
            return getFareFamilyInfo(itineraryId, type)
        default:
            return getFareFamilyInfo(itineraryId, type)
    }
}

export const getFareFamilyInfoCFW = async (itineraryId: string, type = '') => {
    try {
        let partnerId = getPartnerIdCfw()
        const headers = {
            'partner-id': partnerId    
        }
        let URL = `/itin/v7/${itineraryId}/fare-benefits-info`
        if (type) {
            URL = `/itin/v7/${itineraryId}/fare-benefits-info`
        }
        const response = await baseFetch({
            url: URL,
            method: REQUEST_METHODS.GET,
            headers
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.log(e)
        return {}
    }
}
export const getFareFamilyInfo = async (itineraryId: string, type = '') => {
    try {
        let URL = `/itin/v4/${itineraryId}/fare-benefits-info`
        if (type) {
            URL = `/itin/v4/${itineraryId}/fare-benefits-info?insuranceType=${type}`
        }
        const response = await baseFetch({
            url: URL,
            method: REQUEST_METHODS.GET
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.log(e)
        return {}
    }
}

export const initiatePrePaymentCFW = async (itineraryId: string) => {
    try {
        let partnerId = getPartnerIdCfw()
        const headers = {
            'partner-id': partnerId    
        }
        const URL = `/itin/v7/${itineraryId}/prepayment`
        const response = await baseFetch<ResponseType>({
            url: URL,
            method: REQUEST_METHODS.POST,
            headers
        })
        return response
    } catch (e) {
        console.log(e)
    }
}

export const generatePayUrlCFW = async (itineraryId: string, tripId: string) => {
    try {
        const URL = `/itin/v7/${itineraryId}/generatePayUrl?tripId=${tripId}`
        const response = await baseFetch({
            url: URL,
            method: REQUEST_METHODS.GET
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.log(e)
    }
}

import { HomeIcon, Flights, Hotels, Work, Trips, Support, Offers, Bus } from '@/assets/icons'
import {
    TripsMini,
    WalletMini,
    ProfileMini,
    SettingsMini,
    ShortcutsMini,
    TravellersMini,
    Expressway,
    Ticket,
    ChangeFlight,
    Cancellations
} from '@/assets/icons'

export const LOGIN_BANNER_URL =
    'https://rukmini-ct.flixcart.com/f_auto,q_auto,w_410,h_437,dpr_2/offermgmt-prod/offermgmt/bbdJson/Desktop_Banner.png'
export const accoutOptions = [
    {
        text: 'Trips',
        link: '/accounts/trips',
        icon: TripsMini,
        actionName: 'your_trips_trips'
    },
    {
        text: 'Shortlists',
        link: '/account/shortlists',
        icon: ShortcutsMini,
        actionName: 'your_trips_shortlists'
    },
    {
        text: 'Travellers',
        link: '/account/travellers',
        icon: TravellersMini,
        actionName: 'your_trips_travellers'
    },
    {
        text: 'Cleartrip Wallet',
        link: '/accounts/wallet',
        icon: WalletMini,
        actionName: 'your_trips_wallet'
    },
    {
        text: 'Expressway',
        link: '/accounts/expressway',
        icon: Expressway,
        actionName: 'your_trips_expressway'
    },
    {
        text: 'Profile',
        link: '/accounts/profile',
        icon: ProfileMini,
        actionName: 'your_trips_profile'
    },
    {
        text: 'Settings',
        link: '/account/settings',
        icon: SettingsMini,
        actionName: ''
    }
]
export const tools = [
    {
        text: 'Cancellations',
        link: '/accounts/trips',
        icon: Cancellations,
        actionName: 'your_trips_cancellations'
    },
    {
        text: 'Change flight',
        link: '/accounts/trips',
        icon: ChangeFlight,
        actionName: 'your_trips_change_flights'
    },
    {
        text: 'Print ticket',
        link: '/accounts/trips',
        icon: Ticket,
        actionName: 'your_trips_print_ticket'
    },
    {
        text: 'Print flight voucher',
        link: '/vouchers',
        icon: Ticket,
        actionName: 'your_trips_flight_voucher'
    }
]
export const errorConfig = {
    ERROR_IN_SENDING_SMS: 'Failed to send SMS OTP. Please try again.',
    OTP_SEND_LIMIT_EXCEEDED: 'You exceeded the OTP limit. Please try again after 10 minutes.',
    ERROR_IN_SENDING_EMAIL: 'Failed to send email OTP. Please try again.',
    NO_SAVED_OTP_FOUND: 'OTP expired. Please resend OTP and try again.',
    INVALID_OTP: 'Please enter a valid OTP',
    OTP_VALIDATE_LIMIT_EXCEEDED: 'You exceeded the OTP validation limit. Please try again in 10 minutes.',
    INVALID_MOBILE: 'Something went wrong. Please try again.',
    DEFAULT: 'Something went wrong. Please try again.'
}

export const OTP_ERRORS = Object.keys(errorConfig)

export const MOBILE_COUNTRY_CODES = [{ name: 'India', code: '91', min: 10, max: 10 }]
export const HAMBURGER_OPTIONS = [
    {
        icon: HomeIcon,
        text: 'Home',
        link: '/',
        isSelected: false
    },
    {
        icon: Flights,
        text: 'Flights',
        link: '/flights',

        isSelected: true
    },
    {
        icon: Hotels,
        text: 'Hotels',
        link: '/hotels'
    },
    {
        icon: Bus,
        text: 'Bus',
        link: '/bus',
        isSelected: false
    },
    {
        icon: Offers,
        text: 'Offers',
        link: '/all-offers/?categories=flights'
    },
    {
        icon: Trips,
        text: 'My trips',
        link: '/account/trips'
    },
    {
        icon: Work,
        text: 'Cleartrip for Work',
        link: 'https://business.cleartrip.com/'
    },
    {
        icon: Support,
        text: 'Support',
        link: '/support'
    }
]

export const loginBannerImages = [
    {
        url: 'https://rukmini-ct.flixcart.com/f_auto,q_auto,w_350,h_287,dpr_2/offermgmt-prod/offermgmt/images/banner/ct_hotel_pricing_icon_desktop.png',
        backgroundColor: '#FF8057',
    },
    {
        url: 'https://rukmini-ct.flixcart.com/f_auto,q_auto,w_350,h_287,dpr_2/offermgmt-prod/offermgmt/images/banner/ct_flight_pricing_icon_desktop.png',
        backgroundColor: '#FFD468',
    },
    {
        url: 'https://rukmini-ct.flixcart.com/f_auto,q_auto,w_350,h_287,dpr_2/offermgmt-prod/offermgmt/images/banner/ct_cfw_icon_desktop.png',
        backgroundColor: '#5295FF',
    },
    {
        url: 'https://rukmini-ct.flixcart.com/f_auto,q_auto,w_350,h_287,dpr_2/offermgmt-prod/offermgmt/images/banner/uhp_d_login_slideThree.png',
        backgroundColor: '#54BB52',
    },
    {
        url: 'https://rukmini-ct.flixcart.com/f_auto,q_auto,w_350,h_287,dpr_2/offermgmt-prod/offermgmt/images/banner/uhp_d_login_slideFour.png',
        backgroundColor: '#FF8862',
    },
    {
        url: 'https://rukmini-ct.flixcart.com/f_auto,q_auto,w_350,h_287,dpr_2/offermgmt-prod/offermgmt/images/banner/ct_supercoins_discount_icon_desktop.png',
        backgroundColor: '#A06AE5',
    },
];

export const loginHelpText =
    'We no more support email based login. You can now login via mobile number & link email to access your account.'

export const enum HOME_PAGE_COMPONENTS_NAME {
    SEARCH_FIELD = 'searchField',
    SEARCH_BUTTON = 'searchButton',
    TOP_DESTINATION = 'popularDestination',
    TOP_flightS = 'topflights',
    TRENDING_GATEWAYS = 'trendingGateways',
    BSB_BANNER = 'bsbBanner',
    AUTO_SUGGEST_OPTION = 'autoSuggestOption',
    LOGIN_BUTTON = 'loginButton',
    PAX_OPTION = 'paxOption',
    PAX_ADD_MORE = 'paxAddMoreRoom',
    PAX_SHOW_OPTION = 'paxShowOption',
    PAX_ADD_ANOTHER_ROOM = 'paxAddAnotherRoom',
    PAX_BUTTON = 'paxButton',
    PAX_COUNTER = 'paxCounter',
    PAX_REMOVE_ROOM = 'paxRemoveRoom',
    PAX_PERSON_COUNT = 'paxPersonCount',
    CALENDAR = 'calendar',
    MY_ACCOUNT = 'myAccount'
}
export const faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
        {
            '@type': 'Question',
            name: 'What services does Cleartrip offer?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: 'Cleartrip is dedicated to making travel easy, economical, and uncomplicated, from international to domestic flights, from early morning to late night flights, and from budget to luxury flights. Cleartrip allows you to book your flight with just a few clicks. Our features include:\n1. ClearChoice Max offers free cancellation or rescheduling for domestic and international flights.\n2. ClearChoice Plus: Free date or airline change up to 12 hours before departure.\n3. Medical reasons to cancel your domestic travel, and receive up to INR3500 compensation against airline cancellation charges per person per segment.',
            },
        },
        {
            '@type': 'Question',
            name: 'How do I create an account on Cleartrip?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: "For all your travel bookings, sign up on Cleartrip. \n1. Go to the home page, click the link (top right), and enter your email address. We'll then send you an account activation link.\n2. Click it, and you'll be able to access your Cleartrip account.\nThe account activation link expires three days after you provide us with your email address, so please activate your account within that time frame.",
            },
        },
        {
            '@type': 'Question',
            name: 'How can I contact Cleartrip customer support?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: 'You can reach Cleartrip Customer Support and Phone Number. Contact us at +91 9595333333. We are here to assist you with your booking and rescheduling queries.',
            },
        },
        {
            '@type': 'Question',
            name: 'How do I search for flights on Cleartrip?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: "We make it relatively simple to search and book Cleartrip flights\n1. Enter source and destination cities/airports.\n2. Select the date of travel.\n3. Select the number of travellers.\n4. Hit enter, and you're done! You have a search list of all available flights, sorted by price.",
            },
        },
        {
            '@type': 'Question',
            name: 'How do I search for hotels on Cleartrip?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: "Booking a hotel online has never been easier than it is with Cleartrip.\n1. Click the 'hotels' tab on the site.\n2. Enter the city/locality/landmark/hotel name in the search bar.\n3. Enter the check-in and check-out dates.\n4. Choose the number of travellers and press enter.\nYou may also refine your hotel booking search results by utilising parameters such as price, star rating, traveller rating, facilities, and preferences such as hill-view or couple-friendly hotels. Cleartrip offers hotels for all types of stays. Cleartrip hotels are curated to give you the best value for your money.",
            },
        },
        {
            '@type': 'Question',
            name: 'How do I book train tickets on Cleartrip?',
            acceptedAnswer: {
                '@type': 'Answer',
                text: "Cleartrip enables you to buy train tickets and search for them. Simply click 'check availability' on the search results page, and you will find the book button to click for bookings. Booking Cleartrip trains is simple and fast.",
            },
        },
    ],
};

export const flightFaqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "How do you search for and book cheap flights on Cleartrip?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "1. Visit our Flight Status page and input your Cleartrip login details to book cheap flights with us.\n2. Choose your origin and your destination points in the given spaces.\n3. Once you've entered your arrival and departure dates, click \"Go.\" You will be able to book a flight from origin to endpoint. If planning a round-trip, browse or search for flights and book them based on your preferences.\n4. The airfare for all flights will be displayed to you. When you've settled on the best option, make the booking."
        }
      },
      {
        "@type": "Question",
        "name": "How do you make flexible flight bookings with changeable dates?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "When booking your flight, please pick 'ClearChoice Plus' or 'ClearChoice Max' before confirming the ticket. You can get flexible flight tickets and easily change your ticket booking dates or airlines at a reasonable cost. If your plans change, there is nothing to worry about; Cleartrip has you covered."
        }
      },
      {
        "@type": "Question",
        "name": "How do I modify or cancel my flight booking?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Cancel flight tickets online with us without any extra effort.\nIf you desire to cancel your booking for any reason, simply follow the following instructions:\n1. Choose the trip you wish to cancel.\n2. Click the \"Cancellations\" link.\n3. Choose which passengers to cancel the booking for. Then, select \"Review cancellation.\"\n4. Review the passenger selection and refund amount.\n5. Click \"Yes, cancel now.\"\n\nTo modify your flight, select the head you want to modify if you're going to do it yourself. For International or Domestic flights, you can either log into your Cleartrip account and change your flight reservation or call us 24 hours a day, seven days a week."
        }
      },
      {
        "@type": "Question",
        "name": "What are the benefits of booking flights online with Cleartrip?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "When you book your journey with Cleartrip, you will receive the most competitive flight pricing and unique flight offers. Irresistible promotions and discounts, such as the Travel Max Sale, Big Travel Sale, and Cleartrip Tatkal, provide never-before-seen savings, allowing you to book low-cost flights. The best flight savings are available when you book using bank cards such as ICICI, Bank of Baroda, HDFC, Axis, and Kotak. Booking a flight ticket online with Cleartrip can help you get many deals and save money."
        }
      },
      {
        "@type": "Question",
        "name": "Can I book a round-trip flight on Cleartrip?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Booking the whole trip in one go is always advisable for a hassle-free travel experience. Round-trip airline tickets can be searched and quickly booked on Cleartrip; you just need to provide the destinations and dates of travel."
        }
      },
      {
        "@type": "Question",
        "name": "What payment methods are accepted on Cleartrip for flight bookings?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "With Cleartrip it is possible to get the cheapest flight booking done with ease. Aside from credit and debit cards, we accept payments using Internet Banking, UPI, Third Party Wallet, Pay Later, and Cardless EMI."
        }
      },
      {
        "@type": "Question",
        "name": "Does Cleartrip offer travel insurance for flight bookings?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Travel insurance is often available as an add-on when purchasing an airline ticket. Travel insurance is classified as \"flight\" coverage because it has fewer plan limitations than other alternatives. Check the best flight insurance options on Cleartrip today."
        }
      },
      {
        "@type": "Question",
        "name": "Does Cleartrip offer special discounts or promotions on flight bookings?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, flight ticket offers can be availed on Cleartrip. There are multiple offers available on both domestic and international flights."
        }
      },
      {
        "@type": "Question",
        "name": "How do I check my flight status on Cleartrip?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "1. Visit our flight status page and input your Cleartrip login details.\n2. Get real-time flight status, and learn about delayed flights using Cleartrip.\n3. You can check the status by visiting the official website's 'Flight Status' link.\n4. You must follow input information regarding their departure and arrival cities and the date, flight number, and PNR number.\n5. When you click the 'Search Flight' button, all information about the flight is displayed."
        }
      },
      {
        "@type": "Question",
        "name": "What should I do if I don't receive my e-ticket?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "If you can't find an email in your regular inbox, check the promotions/ updates/ spam/ trash folder. Your e-ticket may be delayed due to minor technical issues. Meanwhile, check your email to ensure you have received the invoice and e-ticket. Online flight ticket booking will indeed have your ticket in the mailbox."
        }
      },
      {
        "@type": "Question",
        "name": "Are there any additional fees for booking flights on Cleartrip?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "For domestic reservations of air tickets made within India, Cleartrip levies a non-refundable cancellation fee of INR 300 per passenger per sector in addition to the airline's cancellation fees. If domestic reservations made within India are cancelled with the help of customer service representatives, there will be an INR 500 fee per person per flight."
        }
      },
      {
        "@type": "Question",
        "name": "Can I book a flight for someone else on Cleartrip?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, you can make a booking for anyone on Cleartrip. You just need to have the correct details, like their full name and travel dates. Book my trip or trip for the entire family. Options can be accessed on Cleartrip."
        }
      },
      {
        "@type": "Question",
        "name": "What are the check-in options available for my flight?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Anyone may do the airline check-in online or through the app, beginning 24 hours before departure and continuing up to 45 minutes before your domestic flight. To check bags or in at the airport, you must arrive at a specific time before the scheduled departure. Usually, it's mandatory to reach 45 minutes for domestic flights."
        }
      },
      {
        "@type": "Question",
        "name": "How do I get a refund for a cancelled flight?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You can apply for a flight ticket cancellation refund online, and the money will be credited to the same account you used to make the booking. In the second scenario, where the airlines cancel your booked flight, you are entitled to a full refund. In case of any cancellation or refund concerns, you can email us at wecare@cleartrip.com or 9595333333."
        }
      },
      {
        "@type": "Question",
        "name": "What documents do I need for my flight?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Documents required for domestic flights are given below:-\n1. Your most recent valid identification, such as a PAN or Adhar Card.\n2. A photocopy of your ticket or reservation.\n3. Boarding passes for the flight you plan to board."
        }
      }
    ]
  }
  

import React from 'react'
import { Container, Typography, Spacer } from '@cleartrip/ct-design-components'
import HomePageFaq from './HomePageFaq'
import { useRouter } from 'next/router'

const ctFeatures = [
    {
        heading: 'ClearChoice Max',
        value: ' Free cancellation or rescheduling for domestic (up to 24 hrs before departure) & international flights (up to 72 hrs before departure).'
    },
    {
        heading: 'ClearChoice Plus',
        value: 'Free date change or airline change up to 12 hrs (up to 24 hours for Air India*& Vistara*) before departure.'
    },
    {
        heading: 'Easy hotel cancellation',
        value: 'Cancel your hotel stay easily. Zero fees on hotel cancellations up to 24 hours before check-in on 20k+ hotels.'
    },
    {
        heading: 'Instant refund initiation',
        value: 'All refunds on flight and hotel cancellations are initiated instantly.'
    },
    {
        heading: 'Medi-cancel refund',
        value: 'Cancel your domestic flight booking easily on valid medical grounds and get up to ₹3500 against airline cancellation charges per passenger per segment.'
    },
    {
        heading: 'International travel insurance',
        value: 'Get stress-free coverage against a vast range of uncertainties for all international destinations at only ₹89 per user per day.'
    },
    {
        heading: 'Special Fares For Armed Personnel, Senior Citizens and Student travellers',
        value: 'Cleartrip introduces discounted fares for armed personnel, senior citizens and students on domestic flights. Additional discounts with coupon codes.'
    }
]

const ctFlightsFeatures = [
    {
        heading: 'ClearChoice Max',
        value: 'It offers free cancellation or rescheduling for domestic (up to 24 hours before departure) and international flights (up to 72 hours before departure).'
    },
    {
        heading: 'ClearChoice Plus',
        value: 'Free date or airline change up to 12 hours (or 24 hours for Air India* and Vistara*) before departure.'
    },
    {
        heading: 'Easy hotel cancellation',
        value: ' You can quickly cancel your hotel stay. There are no costs for hotel cancellations up to 24 hours before check-in at over 20,000 hotels.'
    },
    {
        heading: 'Instant refund initiation',
        value: 'All flight and hotel cancellation refunds are processed immediately.'
    },
    {
        heading: 'Medi-cancel refund',
        value: 'Cancel your domestic flight booking for sound medical reasons and receive up to INR 3500 against airline cancellation charges per person per segment.'
    },
    {
        heading: 'International travel insurance',
        value: 'For only INR 89 per user daily, you can get stress-free coverage against a wide range of uncertainties for all foreign destinations.'
    },
    {
        heading: 'Special fares for armed personnel, senior citizens, and student travellers',
        value: 'Cleartrip has introduced lower pricing on domestic flights for armed personnel, senior citizens, and students. Coupon codes provide further discounts.'
    }
]
const HOMEFAQData = [ 
    {
      heading: 'FAQs: Flights, Hotels, Bus and Train Booking on Cleartrip',
      values: [
        {
          question: 'What services does Cleartrip offer?',
          answer: {
            section1: 'Cleartrip is dedicated to making travel easy, economical, and uncomplicated, from international to domestic flights, from early morning to late night flights, and from budget to luxury flights. Cleartrip allows you to book your flight with just a few clicks. Our features include:',
            section2: [
               "ClearChoice Max offers free cancellation or rescheduling for domestic and international flights.",
               "ClearChoice Plus: Free date or airline change up to 12 hours before departure.",
               "Medical reasons to cancel your domestic travel, and receive up to INR3500 compensation against airline cancellation charges per person per segment."
            ],
            section3: ''
          }
        },
        {
          question: 'How do I create an account on Cleartrip?',
          answer: {
            section1: 'For all your travel bookings, sign up on Cleartrip.',
            section2: [
              "Go to the home page, click the link (top right), and enter your email address. We'll then send you an account activation link.",
              "Click it, and you'll be able to access your Cleartrip account."
            ],
            section3: 'The account activation link expires three days after you provide us with your email address, so please activate your account within that time frame.'
          }
        },
        {
            question: 'How can I contact Cleartrip customer support?',
            answer: {
              section1: 'You can reach Cleartrip Customer Support and Phone Number. Contact us at +91 9595333333. We are here to assist you with your booking and rescheduling queries.',
              
            }
        },
        {
            question: 'How do I search for flights on Cleartrip?',
            answer: {
              section1: 'We make it relatively simple to search and book Cleartrip flights',
              section2: [
                "Enter source and destination cities/airports.",
                "Select the date of travel.",
                "Select the number of travellers.",
                "Hit enter, and you're done! You have a search list of all available flights, sorted by price."
              ],
              
            }
        },
        {
            question: 'How do I search for hotels on Cleartrip?',
            answer: {
              section1: 'Booking a hotel online has never been easier than it is with Cleartrip.',
              section2: [
                "Click the 'hotels' tab on the site.",
                "Enter the city/locality/landmark/hotel name in the search bar.",
                "Enter the check-in and check-out dates.",
                "Choose the number of travellers and press enter."
              ],
              section3: 'You may also refine your hotel booking search results by utilising parameters such as price, star rating, traveller rating, facilities, and preferences such as hill-view or couple-friendly hotels. Cleartrip offers hotels for all types of stays. Cleartrip hotels are curated to give you the best value for your money.'
              
            }
        },
        {
            question: 'How do I book train tickets on Cleartrip?',
            answer: {
              section1: "Cleartrip enables you to buy train tickets and search for them. Simply click 'check availability' on the search results page, and you will find the book button to click for bookings. Booking Cleartrip trains is simple and fast",
            }
        },
      ]
    },
  ]
const FLIGHTSFAQData = [ 
    {
      heading: 'FAQs: Flights on Cleartrip',
      values: [
        {
            answer: {
                section1 : "When you try to search or book flights, there could be many doubts that you would like to clarify. Here is a list of FAQs to have all your answers together:-"
            }
        }
        ,
        {
          question: 'How do you search for and book cheap flights on Cleartrip?',
          answer: {
            section2: [
               "Visit our Flight Status page and input your Cleartrip login details to book cheap flights with us.",
               "Choose your origin and your destination points in the given spaces.",
               "Once you've entered your arrival and departure dates, click \"Go.\" You will be able to book a flight from origin to endpoint. If planning a round-trip, browse or search for flights and book them based on your preferences.",
               "The airfare for all flights will be displayed to you. When you've settled on the best option, make the booking."
            ],
          }
        },
        {
          question: 'How do you make flexible flight bookings with changeable dates?',
          answer: {
            section1: "When booking your flight, please pick 'ClearChoice Plus' or 'ClearChoice Max' before confirming the ticket. You can get flexible flight tickets and easily change your ticket booking dates or airlines at a reasonable cost. If your plans change, there is nothing to worry about; Cleartrip has you covered.",
          }
        },
        {
            question: 'How do I modify or cancel my flight booking?',
            answer: {
              section1: "Cancel flight tickets online with us without any extra effort.If you desire to cancel your booking for any reason, simply follow the following instructions:",
              section2: [ "Choose the trip you wish to cancel.","Click the \"Cancellations\" link.","Choose which passengers to cancel the booking for. Then, select \"Review cancellation.\"","Review the passenger selection and refund amount."," Click \"Yes, cancel now.\""

              ],
              section3: "To modify your flight, select the head you want to modify if you're going to do it yourself. For International or Domestic flights, you can either log into your Cleartrip account and change your flight reservation or call us 24 hours a day, seven days a week."
              
            }
        },
        {
            question: 'What are the benefits of booking flights online with Cleartrip?',
            answer: {
              section1: 'When you book your journey with Cleartrip, you will receive the most competitive flight pricing and unique flight offers. Irresistible promotions and discounts, such as the Travel Max Sale, Big Travel Sale, and Cleartrip Tatkal, provide never-before-seen savings, allowing you to book low-cost flights. The best flight savings are available when you book using bank cards such as ICICI, Bank of Baroda, HDFC, Axis, and Kotak. Booking a flight ticket online with Cleartrip can help you get many deals and save money.',  
            }
        },
        {
            question: 'Can I book a round-trip flight on Cleartrip?',
            answer: {
              section1: "Booking the whole trip in one go is always advisable for a hassle-free travel experience. Round-trip airline tickets can be searched and quickly booked on Cleartrip; you just need to provide the destinations and dates of travel." 
            }
        },
        {
            question: 'What payment methods are accepted on Cleartrip for flight bookings?',
            answer: {
              section1: "With Cleartrip it is possible to get the cheapest flight booking done with ease. Aside from credit and debit cards, we accept payments using Internet Banking, UPI, Third Party Wallet, Pay Later, and Cardless EMI",
            }
        },
        {
            question: 'Does Cleartrip offer travel insurance for flight bookings?',
            answer: {
              section1: "Travel insurance is often available as an add-on when purchasing an airline ticket. Travel insurance is classified as \"flight\" coverage because it has fewer plan limitations than other alternatives. Check the best flight insurance options on Cleartrip today.",
            }
        },
        {
            question: 'Does Cleartrip offer special discounts or promotions on flight bookings?',
            answer: {
              section1: "Yes, flight ticket offers can be availed on Cleartrip. There are multiple offers available on both domestic and international flights.",
            }
        },
        {
            question: 'How do I check my flight status on Cleartrip?',
            answer: {
              section2: ["Visit our flight status page and input your Cleartrip login details.",
                "Get real-time flight status, and learn about delayed flights using Cleartrip.",
                "You must follow input information regarding their departure and arrival cities and the date, flight, number, and PNR number.",
                "When you click the 'Search Flight' button, all information about the flight is displayed."
              ],
            }
        },
        {
            question: "What should I do if I don't receive my e-ticket?",
            answer: {
              section1: "If you can't find an email in your regular inbox, check the promotions/ updates/ spam/ trash folder. Your e-ticket may be delayed due to minor technical issues. Meanwhile, check your email to ensure you have received the invoice and e-ticket. Online flight ticket booking will indeed have your ticket in the mailbox.",
            }
        },
        {
            question: "Are there any additional fees for booking flights on Cleartrip?",
            answer: {
              section1: "For domestic reservations of air tickets made within India, Cleartrip levies a non-refundable cancellation fee of INR 300 per passenger per sector in addition to the airline's cancellation fees. If domestic reservations made within India are cancelled with the help of customer service representatives, there will be an INR 500 fee per person per flight.",
            }
        },
        {
            question: "Can I book a flight for someone else on Cleartrip?",
            answer: {
              section1: "Yes, you can make a booking for anyone on Cleartrip. You just need to have the correct details, like their full name and travel dates. Book my trip or trip for the entire family. Options can be accessed on Cleartrip.",
            }
        },
        {
            question: "What are the check-in options available for my flight?",
            answer: {
              section1: "Anyone may do the airline check-in online or through the app, beginning 24 hours before departure and continuing up to 45 minutes before your domestic flight. To check bags or in at the airport, you must arrive at a specific time before the scheduled departure. Usually, it's mandatory to reach 45 minutes for domestic flights.",
            }
        },
        {
            question: "How do I get a refund for a cancelled flight?",
            answer: {
              section1: "You can apply for a <a style='text-decoration: none' href='https://www.cleartrip.com/faq/account/tour/flights_cancellation.shtml'> flight ticket cancellation </a> refund online, and the money will be credited to the same account you used to make the booking. In the second scenario, where the airlines cancel your booked flight, you are entitled to a full refund. In case of any cancellation or refund concerns, you can email us at wecare@cleartrip.com or 9595333333.",
            }
        },
        {
            question: "What documents do I need for my flight?",
            answer: {
              section1: "Documents required for domestic flights are given below:-",
              section2: ["Your most recent valid identification, such as a PAN or Adhar Card.",
                "A photocopy of your ticket or reservation.",
                "Boarding passes for the flight you plan to board."
              ]
            }
        },
      ]
    },
  ]

const FAQ = ({ data = [] }) => {
    return (
      <Container>
        {data.map((faq, index) => (
          <div key={index} className="pt-8"> 
            <h2 className="pb-4 fw-600 c-neutral-900" style={{ fontSize: '18px' }}>
              {faq.heading}
            </h2>
            {faq?.values?.map((item, subIndex) => (
              <div key={subIndex}>
                {item?.question && <h2 className="pt-4 fw-600 c-neutral-900" style={{ fontSize: '18px' }}>
                  {item?.question}
                </h2>}
                {item?.answer?.section1 && <p className="pt-4 c-dark-grey fs-14" dangerouslySetInnerHTML={{ __html: item?.answer?.section1 }}/>}
                {item?.answer?.section2 && (
                  <ul className="px-6 pt-2" style={{ listStyleType: 'decimal' }}>
                    {item.answer.section2.map((listItem, itemIndex) => (
                      <li key={itemIndex} className="pt-1 c-dark-grey fs-14">
                        {listItem}
                      </li>
                    ))}
                  </ul>
                )}
                {item?.answer?.section3 && (
                  <p className="pt-2 c-dark-grey fs-14">{item.answer.section3}</p>
                )}
              </div>
            ))}
          </div>
        ))}
      </Container>
    );
  };

const FAQs = () => {
    const router = useRouter()
    const { pathname } = router

    return (
        <>
            <Container className="pt-5" />
            {pathname === '/flights' ? (
                <>
                <div>
                    <h3 className=" fw-600 c-neutral-900" style={{fontSize:'18px'}}>Book your flights with Cleartrip for a memorable experience</h3>
                    <p className="pt-4 c-dark-grey fs-14">
                    Cleartrip is your one-stop solution for all travel booking requirements. Whether it's a business trip, family vacation, hotel or flight bookings, all these can be done smoothly. There are so many options available that you have the flexibility to choose as per your needs.
                    </p>
                    <p className="pt-4 c-dark-grey fs-14">
                    Our round-trip and multi-city options make it easier to <a href='https://www.cleartrip.com/' className='td-none'> book cheap flights</a>  and put your journey together. We constantly work in a direction to deliver on our commitment to providing people with the most profound travel experience possible via award-winning mobile and desktop solutions. We place clients at the centre of everything we do by giving intuitive products with the maximum flight and hotel options.
                    </p>
                    <p className="pt-4 c-dark-grey fs-14">
                    <strong>Booking a flight ticket online</strong> was always effortful. All you need to do is make an account with us and start the experience of comfortable and worry-free trips. For <strong>ticket booking</strong>, you can press enter once you have given your origin and destination cities; your search list page displays the results for both the onward and return legs in a split-screen manner, allowing you to select flights for a round trip in one go. The multi-city search page displays a selection of complete itineraries, saving you the trouble of calculating time, transfers, and layovers and allowing you to make your online <strong>flight booking</strong>. If you are travelling one way for now and want to book the return later, that can also be done to allow you mobility and ease of booking. We highlight special deals and other promotions on the checkout page to ensure you receive the most excellent deal. Our platform enables easy cancellations or adjustments after booking, eliminating the hassle of needing to call the airlines.
                    </p>
                </div>
                <HomePageFaq showFlightsFaq={true} />
                <FAQ data={FLIGHTSFAQData}/>
                <Spacer className="pt-8" />

                <h3 className=" fw-600 c-neutral-900" style={{fontSize:'18px'}}>Disclaimer</h3>
                <p className="pt-4 c-dark-grey fs-14">
                In addition to the above, the data presented here cannot be assumed reliable; instead, it may vary over time in response to changing demands.
                </p>
            </>
            ) :(<>
                <div>
                    <h3 className="fw-600 c-neutral-900" style={{fontSize:'18px'}}>About Cleartrip</h3>
                    <p className="pt-4 c-dark-grey fs-14">Launched in July 2006, <strong>Cleartrip Pvt Ltd</strong>., a Flipkart company, has emerged as India's fastest-growing online travel technology company. In April 2021, Flipkart acquired a majority stake in Cleartrip. Cleartrip recently emerged as the no. 2 OTA player as per a study by VIDEC. With an aggressive plan to emerge as a leading innovator in the industry, Cleartrip is on its way to building a differentiated value proposition for its customers looking for end-to-end travel solutions. With industry-first offerings including <a href="/lp/ct-flex" className='td-none'>ClearChoice Plus</a>, <a href="/lp/ct-flexmax" className='td-none'>ClearChoice Max</a> and <a href="/lp/cfnr#:~:text=CFNR%20will%20give%20you%20an,What%20is%20Covered?" className='td-none'>Cancel for No Reason</a>', Cleartrip has a clear vision to provide innovative solutions in the OTA segment. Combining intuitive products with a customer-centric approach and a wide selection of flights, hotels, buses and holiday packages, Cleartrip brings a unique selling point to the market, offering its customers convenience, choice, competitive prices, and premium content.</p>
                </div>
                <div>
                    <h3 className=" fw-600 c-neutral-900 pt-5" style={{fontSize:'18px'}}>How Does Cleartrip Help You Plan Your Travel?</h3>
                    <p className="pt-4 c-dark-grey fs-14">
                    <strong>Cleartrip </strong> offers a collection of everything you need for a perfect trip. All can be done simultaneously, from flights to taxis to hotel bookings. This is how planning flights and hotels with Cleartrip gives you a clear edge:-
                    </p>
                    {ctFlightsFeatures.map((feature) => {
                        return (
                            <div className='pt-3'>
                                <span className='fs-14 fw-600 c-neutral-900'>{feature.heading}:</span>&nbsp;
                                <span className='c-dark-grey fs-14'>{feature.value}</span>
                            </div>
                        )
                    })}
                </div>
                <HomePageFaq showFlightsFaq={true} />
                <FAQ data={HOMEFAQData}/>
                <Spacer className="pt-8" />
                <h3 className=" fw-600 c-neutral-900" style={{fontSize:'18px'}}>Disclaimer</h3>
                <p className="pt-4 c-dark-grey fs-14">
                In addition to the above, the data presented here cannot be assumed reliable; instead, it may vary over time in response to changing demands.
                </p>
            </>)}
            
            <Container height="60px" />
            <Container className="pt-15 mt-10" />
        </>
    )
}

export default FAQs
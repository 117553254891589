export enum RAVEN_PAGE_NAME {
    HOME = 'home',
    CONFIRMATION = 'confirmation',
    FlightSHOME = 'flights',
    FLIGHTS_HOME = 'flights_home',
    FLIGHTS_SRP = 'flights_srp'
}

export enum RAVEN_COMMON_KEYS {
    PLATFORM = 'platform',
    LOGIN_STATUS = 'login_status',
    DOMAIN = 'domain',
    VERTICAL = 'vertical',
    PAGE_NAME = 'page_name',
    CURRENCY = 'currency',
    STATSTIG_AB_KEY = 'h_ab_key'
}

export enum RAVEN_EVENT_NAME {
    HOME_PAGELOAD = 'a_home_pageload',
    HOME_M_HOMEPAGE_LOAD = 'a_m_homepage_load',
    HOME_SEARCH_CLICK = 'a_hp_search_click',
    HOME_DEPARTURE_AIRPORT_SELECTED = 'a_hp_departure_airport_selected',
    HOME_ARRIVAL_AIRPORT_SELECTED = 'a_hp_arrival_airport_selected',
    HOME_ARRIVAL_AIRPORT_NULL_SEARCH = 'a_hp_arrival_airport_null_search',
    HOME_DEPARTURE_AIRPORT_NULL_SEARCH = 'a_hp_departure_airport_null_search',
    HOME_RT_DATE_SELECTED = 'a_hp_rt_date_selected',
    HOME_OW_DATE_SELECTED = 'a_hp_ow_date_selected',
    HOME_RT_CALENDAR_OPENED = 'a_hp_rt_calendar_opened',
    HOME_OW_CALENDAR_OPENED = 'a_hp_ow_calendar_opened',
    HOME_PAX_UPDATED = 'a_hp_pax_updated',
    HOME_TRAVEL_CLASS_UPDATED = 'a_hp_travel_class_updated',
    HOME_PTC_ICON_HOVER = 'a_ptc_icon_hover',
    HOME_PTC_FARE_SELECTED = 'a_ptc_fare_selected',
    HOME_JOURNEY_CLICKED = 'a_hp_select_journey_clicked',
    CFNR_LEARN_MORE_CLICK = 'h_cfnr_learn_more_click',
    LOGIN_CLICK = 'u_login_click',
    LOGIN_ERROR = 'u_login_error',
    LOGIN_SUCCESS = 'u_login_success',
    LOGIN_POPUP = 'u_login_popup',
    SRP_PAGELOAD = 'a_srp_pageload',
    SRP_FAILURE = 'a_srp_page_failure',
    SRP_SEARCH_CLICK = 'a_srp_search_click',
    A_UI_ACTION = 'a_ui_action'
}

export enum RAVEN_ATTRIBUTE_NAME {
    HP_SEARCH_FLIGHT = 'search_flight_hp',
    HP_PTC_ICON_HOVER = 'ptc_icon_hover',
    HP_JOURNEY_CLICKED = 'a_select_journey_click',
    HP_DEPARTURE_AIRPORT_SELECTED = 'a_departure_airport_selected',
    HP_ARRIVAL_AIRPORT_SELECTED = 'a_arrival_airport_selected',
    HP_OW_CALENDAR_OPENED = 'a_ow_calendar_opened',
    HP_OW_DATE_SELECTED = 'a_ow_date_selected',
    HP_RT_CALENDAR_OPENED = 'a_rt_calendar_opened',
    HP_RT_DATE_SELECTED = 'a_rt_date_selected',
    HP_PAX_UPDATED = 'a_pax_updated',
    HP_TRAVEL_CLASS_UPDATED = 'a_travel_class_updated',
    HP_DEPARTURE_AIRPORT_NULL_SEARCH = 'a_departure_airport_null_search',
    HP_ARRIVAL_AIRPORT_NULL_SEARCH = 'a_arrival_airport_null_search'
}

export enum MULTITRACKING_EVENT_NAME {
    ON_CLICK = 'onClick',
    ON_SCROLL = 'onScroll'
}
